var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "LBL0001948",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.moc.committees,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "sopChangeCommitteeMemberId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "LBLADD",
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addComittee },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.removeComittee },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSaveComittee,
                          url: _vm.saveComitteeUrl,
                          param: _vm.grid.saveData,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveComittee,
                          btnCallback: _vm.saveComitteeCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table1",
          staticClass: "q-mt-sm",
          attrs: {
            title: "LBL0001949",
            columns: _vm.grid2.columns,
            gridHeight: _vm.grid2.height,
            data: _vm.moc.committeeReviewItems,
            filtering: false,
            usePaging: false,
            columnSetting: false,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "sopChangeCommitteeReviewItemId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "LBLADD",
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addReviewItem },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.removeReviewItem },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSaveReviewItem,
                          url: _vm.saveReviewItemUrl,
                          param: _vm.grid2.saveData,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveReviewItem,
                          btnCallback: _vm.saveReviewItemCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }